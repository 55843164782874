import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/NewsletterTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "934px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "27.599999999999998%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA3XAAAN1wFCKJt4AAABCElEQVQY042RQUsCcRDF/VB9g+59gk6eOhTRpaBbh5CgYCuiTkYGah6CSIwiyiRbiiyVFg+5EoS6LO1/XS0oXffXriC1atCDOcyDeW/mTQAXjuMwhFHcH/g9H/CaPqG/W5T0V1ShUTbqjDIb7L/abR/vE8y8KEjyMfv5NHuFS87kO0LhCBvxBOuxBPHTc0K7EVJZGV2YhI+SZAvF3qzd7f4I9h287WpNgfho8WzWWdjaZnFzh+DSMtMra0zMzTO7KjE2GewJj0/NcOKKe+jY9vCGWsvkvlom55ZqamhvBqnrG26fFDIPeYTVRIoecHiR5ir3iKJWMBqW/+TBbDynz46by/9/4sv1G2shujMHTg19AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png",
          "srcSet": ["/static/c9103bc33c8add5ab1fa4fa1c49c90ef/43fa5/logo-wordmark.png 250w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/c6e3d/logo-wordmark.png 500w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png 934w"],
          "sizes": "(max-width: 934px) 100vw, 934px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "welcome",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#welcome",
        "aria-label": "welcome permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Welcome`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`Models, models, and models!`}</p>
    <p>{`This month we’re bringing you over `}<strong parentName="p">{`70 new models`}</strong>{`!`}</p>
    <p>{`We’ve added new TTS models that leverage our recent research results to bring
you even higher quality speech. Not only TTS; we’ve added STT models too.
Our community created over 70 STT models in over 45 languages! The community’s
got our back!`}</p>
    <p>{`We’re bursting at the seams with all of these new models. So we officially
opened our Model Zoo, which provides a single location to find all our models
and the models of our insanely productive community.`}</p>
    <p>{`Enjoy 🐸!`}</p>
    <h3 {...{
      "id": "-tts-v0013-is-out",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-tts-v0013-is-out",
        "aria-label": " tts v0013 is out permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`🐸💬 TTS v0.0.13 is out`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/erogol"
      }}>{`Eren Gölge`}</a></p>
    <p>{`In this new version we release 2 new TTS models`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`SC-Glow TTS Model`}</strong>{` - English multi-speaker model from our latest research`}</li>
      <li parentName="ul"><strong parentName="li">{`Non-Binary English TTS Model`}</strong>{` - English model that is outside the gender binary`}</li>
    </ul>
    <p>{`and a ton of code updates.`}</p>
    <p>{`You can use these two models and all the other released models either on the command line or with our TTS demo server.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`> tts-server --model_name tts_models/en/vctk/sc-glow-tts
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/static/tts-demo-server-80fdae2ed11821e3ca6ebb27429e011c.gif",
        "alt": "IMAGE"
      }}></img></p>
    <h4 {...{
      "id": "sc-glowtts-model",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#sc-glowtts-model",
        "aria-label": "sc glowtts model permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`SC-GlowTTS Model`}</h4>
    <p>{`This model is trained on `}<a parentName="p" {...{
        "href": "https://datashare.ed.ac.uk/handle/10283/2651"
      }}>{`VCTK`}</a>{` dataset which
includes 110 English speakers with different accents. Each speaker reads out about 400
sentences. You can use this model to generate speech similar to these 110 English
speakers.`}</p>
    <p>{`A `}<a parentName="p" {...{
        "href": "https://arxiv.org/abs/2010.05646"
      }}>{`HiFiGAN`}</a>{` vocoder accompanies this model too, to convert
the output features to the speech waveform. The whole system is able to generate speech
almost faster than real-time on a CPU with a high quality.`}</p>
    <p>{`You can find more details about SC-GlowTTS in our latest `}<a parentName="p" {...{
        "href": "https://arxiv.org/abs/2104.05557"
      }}>{`paper`}</a>{`.`}</p>
    <h4 {...{
      "id": "non-binary-english-tts-model-joint-waccenture",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#non-binary-english-tts-model-joint-waccenture",
        "aria-label": "non binary english tts model joint waccenture permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Non-Binary English TTS Model (Joint w/Accenture)`}</h4>
    <p>{`We worked with `}<a parentName="p" {...{
        "href": "https://www.accenture.com/us-en/about/accenture-labs-index"
      }}>{`Accenture Labs`}</a>{` to create a
non-binary, general purpose TTS voice.`}</p>
    <p>{`This is an important step forward to embrace social diversity in speech tech. We’re
thankful to Accenture Labs for helping us to make this possible.`}</p>
    <p>{`This model uses `}<a parentName="p" {...{
        "href": "https://coqui.ai/blog/tts/solving-attention-problems-of-tts-models-with-double-decoder-consistency/"
      }}>{`Tacotron2 Double Decoder Consistency`}</a>{`
with a `}<a parentName="p" {...{
        "href": "https://arxiv.org/abs/2010.05646"
      }}>{`HiFiGAN`}</a>{` vocoder that in combination, delivers
real-time speech synthesis.`}</p>
    <h3 {...{
      "id": "model-zoo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#model-zoo",
        "aria-label": "model zoo permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Model Zoo`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/JRMeyer"
      }}>{`Josh Meyer`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.8%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAADJElEQVQozwEZA+b8AJigm5+fkKeklauklKSej6WfkreijLWlkLCkkLGhjrCjkKmeiKGahpqVg5SOgJ2Wfn94b4OAeaamkMK5nABYaGmcr5PBxcqvq5prdYdtgJi5fmmdgXyqpZi2p5awn4+9tLCwoourj2RYYIOFgoB6b29UUVG6w63t1ZoAiYZ31dzkyca+qqWQpYpqpYRgi4Z/bm98rI1roH9f18W87vT/68qp/r+Bv6GfjJqKUGdGVkIzsru9w9HQAI6KhrKpiHFoeWxSU9SRRJ5iIq91N65zNalyM65/NfLiiPHs2c69qbSjjbakbsHxp7nys3iqe2qOdJrG4ACamHJdVUopDyJ0TyrYqGxSNi5QIQCwYx7YvXrUvoy9mkvlyXfSr3vDmmikfTLIkmfbiICRuIvT2VrN4LwAy5tMOjwqR0dPy7h99tp/ontTrGkw1no1wppatqBvVjEMjGM02rqBtodKd0cYsYttzaaPaqeIztIv6NpEAFsYCys6SqdpT5CAdH95ZIV5YKBbM711IkwnAFguBp1UAMyBN+CdgI5pKXw2D8yPbsqliE+nUXalZqSrmABgNi0TIyhSIAyEdnZfa4tbVFJiQSF+QQAiCQRaJgCdQjWqLzvUeJDTim5gcCSIdCdhIQd0oqd5lJmjr6wAdD4zglVIGAUENzgui4NsNSshinBTuogzhF4cqCoL7mRNoD5Wl0p+ZTZRETkQXWgSlFkYTEwzWFA3VUgiABQFAVsmDnBIK11dTh0XCi8rJ6+ddvzlgMadS3syAl0nByghD1RYTkhEKWJTFJyAPs6ze2JcMCgmHDs5MQA3EQh2aQpoXiWMenKPgmg/OCVIPSFfUytpWCMvIgA3NiqWinbp2MJdU0QhIhEnKB0oJyI6OzWsppns5tkAnCoUrkgVy1gswk03sqGMWlZLMTArOTo5S0tIc3Bo2cu28eTO5d3L4trMycS7w7+3yMS62tXJ6uba497SAMZEH9RLK9ZaOdaLbebfzu7m0uriz+zk0vPq1/bt2+nh0uPdzuTe0OTf0Onk1Onj0+fg0OTez9/Zy9vVxJCeh0HAtUD1AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/0d10c8b41ecc5943da153808e4c92ff0/7abe2/model-zoo.png",
          "srcSet": ["/static/0d10c8b41ecc5943da153808e4c92ff0/43fa5/model-zoo.png 250w", "/static/0d10c8b41ecc5943da153808e4c92ff0/c6e3d/model-zoo.png 500w", "/static/0d10c8b41ecc5943da153808e4c92ff0/7abe2/model-zoo.png 900w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`On `}<a parentName="p" {...{
        "href": "https://twitter.com/coqui_ai/status/1381634290891493377"
      }}>{`April 12`}</a>{` we officially opened
the `}<a parentName="p" {...{
        "href": "https://coqui.ai/models"
      }}>{`Coqui model zoo`}</a>{`, and we are already hosting 73 open models
in 48 languages from 11 community members and organizations!`}</p>
    <p>{`The community has been asking for this model zoo for a long time, and we’re happy to have
so many great contributions so fast. This was not possible without your support, and we
owe a big thank you to all the folks who put in the time and effort to train these models!`}</p>
    <p>{`The model zoo is a public repository where you can browse and download pre-trained models
to deploy with Coqui tools. Pre-trained Speech-to-Text models are easily adaptable to
your specific use-case via an external, custom language model. If you have text data
related to your use case, you can `}<a parentName="p" {...{
        "href": "https://stt.readthedocs.io/en/latest/LANGUAGE_MODEL.html"
      }}>{`easily train a new language model`}</a>{`
for any of the released STT models, and get better performance for your application.`}</p>
    <p>{`We want to support anyone who is training their own models and wants to publish their
models to the zoo, so get in touch with us on `}<a parentName="p" {...{
        "href": "https://gitter.im/coqui-ai"
      }}>{`Gitter`}</a>{` and
let’s open-source more and better models for all languages!`}</p>
    <p>{`Thank you to model contributors so far:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/robinhad/"
        }}>{`Yurii Paniv`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/dewibrynjones/"
        }}>{`Dewi Bryn Jones`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/ccoreilly"
        }}>{`Ciaran O’Reilly`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/AASHISHAG"
        }}>{`Aashish Agarwal`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/comodoro"
        }}>{`Vojtěch Drábek`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Mte90"
        }}>{`Daniele Scasciafratte`}</a>{` + `}<a parentName="li" {...{
          "href": "https://github.com/MozillaItalia"
        }}>{`Mozilla Italia`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/ftyers/"
        }}>{`Francis Tyers`}</a>{` + `}<a parentName="li" {...{
          "href": "https://itml.cl.indiana.edu/"
        }}>{`ITML`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://digitalumuganda.com/"
        }}>{`Digital Umuganda`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://gitlab.com/Jaco-Assistant"
        }}>{`Jaco Assistant`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/common-voice/commonvoice-fr"
        }}>{`Common Voice FR`}</a></li>
    </ul>
    <h3 {...{
      "id": "stt-model-sprint",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#stt-model-sprint",
        "aria-label": "stt model sprint permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`STT Model Sprint`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/JRMeyer"
      }}>{`Josh Meyer`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "45.2%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAACHElEQVQozxWQ627SAACFm/gUPoRv4Q//7YeocYtK3AZm6mbAcesY92K70o5LKRRoS4FybSljjssgchMnG8oKyGU+gI9hl5wfJzk5yTkf8PDRg/3PT657pX6vj8VShWqdKp5/oQsnbLHc7DJizUXlPLEiW25QGSHK5drtbq3REqRzji8CO9rHk+/l1fQWo9JoLCU1O554wRPLe+m82OwmhAsbyYOhDJqU0lIdJhkuL5akr9lSJZbkgX8rmU7l34EQzvCVy549kgOJzDGZdUT4s1YvVWmAobTJn9b7WIQWxEYHxKOQn0zmRYYvAXsWl9pgx9h8pdXX+egPcEKHseZg2hzkpMt+ptoynLJ6jN1H4lp3xBbOnrUGLpI7cKIoSQO7R5AjzDW7P+wRXocx1jDvpHJQvOCmcs3+SGz0lM/OaM5K8FYi8wllYFqoda4OscSOyQUcB+LD0S+cKzuiWXskS2SrTLkBMwLKCp3hzcW3IUyXoFhBAcaIdTdVUFYE0pX2YLTnIYCUVFuv/07kxc+xPBhNRuPpzWR2O10sl3fy9I88W8jz5dVYvp7MlfTe/J7N5su71Rpni8CW5WQLxNSO0Fsnse0m3yPxAx+tSHluDKSMfs7g5xQQGk902x15bSfeOIhXttBL8PS5EQVUh16VAXlhwTeP/GpXeBeiNFBU66U+Igq5pB6/l8ZDKbVNa/CZGVeZsKcmbEOPbOjg/zbTSQwrWkVMAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/657e86144e3990f5d99ab10fb61ba094/7abe2/model-sprint.png",
          "srcSet": ["/static/657e86144e3990f5d99ab10fb61ba094/43fa5/model-sprint.png 250w", "/static/657e86144e3990f5d99ab10fb61ba094/c6e3d/model-sprint.png 500w", "/static/657e86144e3990f5d99ab10fb61ba094/7abe2/model-sprint.png 900w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Over the last 3 weeks, one of our core contributors embarked on a model-training sprint to
produce as many open STT models as possible for under-served languages. By the end of the
sprint, Francis Tyers (Professor of Computational Linguistics at Indiana University Bloomington)
had produced models for 34! languages.`}</p>
    <p>{`This model training sprint was important not only because it resulted in the release of
open STT models, but also for the very practical training implications from his findings.`}</p>
    <p>{`Tyers set out to find a set of reproducible steps and methods for training any new language
with Coqui STT, using Transfer Learning and smarter hyperparameter search. During the
sprint, Tyers also created an `}<a parentName="p" {...{
        "href": "https://github.com/ftyers/commonvoice-utils"
      }}>{`open-source command line tool`}</a>{`
(compatible with Coqui STT) for pre-processing speech data and creating language models.`}</p>
    <p>{`The dataset for each language ranged from a mere 30 minutes to a maximum of 12
hours — in any case a very small speech corpus.`}</p>
    <h3 {...{
      "id": "new-stt-packages",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#new-stt-packages",
        "aria-label": "new stt packages permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`New STT Packages`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/reuben"
      }}>{`Reuben Morais`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.39999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAACsklEQVQoz1XSS0wTQRgH8I1nryYeTDx5MWDiQWLizWiiNw4aHwkaowkhaoKBIOFl1RQMCFpbxFYCAQ4QHtYWSKHUYIUC0ifl0Qfbx+52dndmZ3a3tRzKyW15RJMvk7n88v/mm4+CICEJaYxYWQaKIqqKVCpcKokQmMuRXBYTLGAsQokXJQBElgXJFBOnEJ/EIkMkTlZ4pSSzKs6qRDu1+15e9fnXVteWFSISIiJJgCgjQI7j02mOpiQhhSFDMHcQeySJIkv7hT2zxVR2sfzM2dMdel2hkBcgq2ERaeEMk0lQpZ4ZQoAiI63VA3mMuz503rha8ej2daOpJ1/IIxkiGYkow8Ni5xT6D0vHWJWlPzk5FfVfKDt3+VJ5aMlO+51x71wy5BaENBBKyYhPSf/gg85zWUVV5cL+3tTw5ysV50+dPFF3/5p3RLdgeOoZaAYZGvBMmolRxWlDBmNOlsUjTAgC3O4Gtxu0fW03Nj8xtdYYmx6HZ/sD3z6FrIZk4EfE51r5aacELl6c2WE4VBSkqhhr80xsRQLu6NIUH1wA/nnO50iu2mnP98ji+IZj0G83uyZMFM9GIaCxmCaIJZgvehmqqqRmsQBScY9ta7rP2Vs/8bpqtPXucOOtgbpK8/ObQ21Vc+O9VCa9LXCxki/+tuZlIhYLC0SWUn5X3GH5PdSyaKh2dj2c1d+beXvH1lZp1T+YGTVSbHIHsDEhQyM+jSGLpYy2TNo+ECxiAjUcc1hCU93+Mf368KuV/gaPpX6pr9ZpqrWNdFPJWJhNRQFH8yAFRVZC4BDLUHt60jsfd5jD1o+B8U7fWMf6iG51sMXT/9LZWzc52EWtLztC3sWw/9dm0LMZWotHgnR8M5HYSdDbdCK64bYuD71xD+hc5ibXl8aFvobpnmfT72sm31Ub2l/8BV5ybXMXEFGqAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/9c2651a71685868fd28f59178b680874/7abe2/new-stt-packages.png",
          "srcSet": ["/static/9c2651a71685868fd28f59178b680874/43fa5/new-stt-packages.png 250w", "/static/9c2651a71685868fd28f59178b680874/c6e3d/new-stt-packages.png 500w", "/static/9c2651a71685868fd28f59178b680874/7abe2/new-stt-packages.png 900w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Since our last newsletter, we’ve been hard at work to bring our entire matrix of supported
architectures, platforms, and programming languages up to speed in our new Continuous
Integration (CI) system. In the meantime, we’ve brought up Linux, macOS, and Windows
builds and tests for our Python and NodeJS/ElectronJS packages, including Raspbian
and ARMBian Linux on ARMv7 and Aarch64.`}</p>
    <p>{`In the coming weeks we’ll be working to bring up the rest of our supported packages,
including our Java/Android AAR package and our .NET package on NuGet. If you’re
interested in this work, please get in touch on our `}<a parentName="p" {...{
        "href": "https://gitter.im/coqui-ai/STT"
      }}>{`STT chat room`}</a>{`
on Gitter.`}</p>
    <p>{`Version 0.10.0a6 of our packages is now available on PyPI and npmjs for all the platforms
mentioned above. Because PyPI does not support Raspbian and ARMbian, you can grab the
Python wheels directly from our release page. Check out our documentation for how
to get started using our pre-built packages to leverage speech-to-text in your
applications.`}</p>
    {
      /* markdownlint-enable line-length */
    }


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      